.Documents
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:auto;
}

.documents-accueil
{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url("../../Image/classeur.jpg");
    background-size: cover;
    background-position: center;
    color: white;
    width: 100%;
    height: 500px;
}

.documents-data
{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:auto;
    margin-bottom:5%;
}

.documents-data-gauche
{
    display:flex;
    flex-direction:column;
    width:50%;
    height:auto;
}