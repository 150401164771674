.Docs
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:auto;
}

.docs-titre {

    display:flex;
    width:100%;
    height:125px;
    align-items:center;
    justify-content:center;
    background-color:#048b9a;
    color:white;
    text-transform:uppercase;
}

.docs-data {
    display:flex;
    width:100%;
    height:auto;
}

.docs-data-gauche {
    display:flex;
    width:15%;
    height:auto;
    flex-direction:column;
    background-color:whitesmoke;
}

.docs-data-gauche-entete {
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:60px;
    background-color:#f1c85f;
    color:white;
}

.docs-data-gauche-menu {
    display:flex;
    margin-top:20px;
    flex-direction:column;
    align-items:center;
    width:100%;
    height:100%;
}

.docs-data-gauche-menu-lien {
    font-weight:bold;
    font-size:13px;
    width:70%;
    margin-top:10px;
    text-transform:uppercase;
    height:30px;
    border-radius:5%;
    padding:15px;
    text-decoration:none;
    color:white;
    background-color:#048b9a;
    cursor:pointer;
}

.docs-data-droite {
    display:flex;
    flex-direction:column;
    width:85%;
    height:100%;
}

.docs-data-droite-titre
{
    display:flex;
    align-items:center;
    justify-content:center;
    color:white;
    height:60px;
    background-color:#f1c85f;
}

.docs-list
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:auto;
}

.elt-ligne
{
    display:grid;
    text-align:center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows:auto;
    height:60px;
    border-bottom:1px dotted black;
}