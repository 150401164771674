#PopupPolitique{
    display:flex;
    align-items:center;
    justify-content:center;
    position:fixed;
    left:0;
    top:0;
    flex-direction:column;
    width:100%;
    height:100%;
    z-index:4;
    background-color:rgba(0,0,0,0.7);
}

.popup-politique-data
{
    display:flex;
    flex-direction:column;
    align-items:center;
    position:relative;
    justify-content:center;
    width:50%;
    height:450px;
    background-color:white;
    border-radius:5px;
    position:relative;
    border-radius:8px;
}

.popup-politique-data-form 
{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    height:auto;
}

.popup-politique-data-form input
{
    width:90%;
    height:30px;
    margin-top:2%;
    margin-bottom:2%;
}

.popup-politique-data-img-quit
{
    position:absolute;
    right:10px;
    top:10px;
    width:50px;
    height:50px;
}

.popup-politique-actions
{
    display:flex;
    width:100%;
    align-items: center;
    justify-content: center;
}

.popup-politique-actions button
{
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:royalblue;
    color:white;
    cursor:pointer;
    width:200px;
    height:45px;
    border:1px solid royalblue;
    margin-left: 1%;
    margin-right: 1%;
}