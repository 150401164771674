#Popup{
    display:flex;
    align-items:center;
    justify-content:center;
    position:fixed;
    left:0;
    top:0;
    flex-direction:column;
    width:100%;
    height:100%;
    z-index:4;
    background-color:rgba(0,0,0,0.7);
}

.popup-data
{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:50%;
    height:auto;
    background-color:white;
    border-radius:5px;
    position:relative;
    border-radius:8px;
}

.popup-data-form 
{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:100%;
    height:auto;
}

.popup-data-form input
{
    width:90%;
    height:30px;
    margin-top:2%;
    margin-bottom:2%;
}

.popup-data-form textarea
{
    width:90%;
    height:120px;
    white-space: pre;
}

.popup-data-submit
{
    display:flex;
    width:100%;
    align-items:center;
    justify-content: center;
    height:100px;
}

.popup-data-submit button
{
    display:flex;
    align-items:center;
    justify-content:center;
    font-family: "Roboto-Bold";
    text-transform:uppercase;
    width:90%;
    cursor:pointer;
    border:1px solid orange;
    height:40px;
    background-color:orange;
    color:white;
}

.popup-data-img-quit
{
    position:absolute;
    right:10px;
    top:10px;
    width:50px;
    height:50px;
}

.erreur-ligne
{
    display:flex;
    width:100%;
    height:25px;
    align-items:center;
    margin-left:10%;
    justify-content:flex-start;
    font-size:12px;
    color:red;
}

@media screen and (max-width: 600px) 
{
    .popup-data
    {
        width:80%;
        height:500px;
    }

    .popup-data-img-quit
    {
        top:5px;
        height:20px;
        width:20px;
    }

    .popup-data-titre h1
    {
        font-size:12px;
    }

    .popup-data-submit button
    {
        height:30px;
    }

}