body,html,#root {
  top:0;
  margin:0;
  width:100%;
  height:100%;
}

@font-face {
  font-family: "Gaegu-Bold";
  src: local("Gaegu-Bold"),
   url("./Composants/Polices/Gaegu/Gaegu-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
   url("./Composants/Polices/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
   url("./Composants/Polices/Roboto/Roboto-Bold.ttf") format("truetype");

}