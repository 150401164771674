.Commune
{
    display:flex;
    flex-direction:column;
    height:auto;
    width:100%;
}

.commune-entete
{
    display: flex;
    height: 300px;
    z-index: 2;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: white;
    color: royalblue;
    width: 100%;
    list-style: none;
}

.commune-entete h1 
{
    text-decoration: none;
    text-transform: uppercase;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-right: 10px;
}

.commune-entete img
{
    width: 35px;
    height: 35px;
    margin-top: 2px;
    margin-right: 60px;
    margin-left: 60px;
}

.commune-diapo
{
    display: flex;
    width: 500%;
    height: 400px;
}

.commune-diapoG
{
    width: 680px;
    height: 400px;
    object-fit: cover;
}

.commune-diapoM
{
    width: 680px;
    height: 400px;
    object-fit: cover;
}

.commune-diapoD
{
    width: 680px;
    height: 400px;
    object-fit: cover;
}

.commune-diapo img
{
    height: 400px;
    width: 700px;
}

.commune-commu
{
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    color: royalblue;
    text-align: center;
    background-color: white;
    box-shadow: 5px 5px 20px;
}

.commune-commu h1
{
    text-transform: uppercase;
}

.commune-geo
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    width: 100%;
}

.commune-geoG
{
    display: flex;
    align-items: left;
    justify-content: left;
    height: 500px;
    width: 50%;
    flex-direction: column;
    margin-top: 50px;
    color: royalblue;
    margin-right: 50px;
    margin-left: 25px;
}

.commune-geoG p
{
    color: black;
}

.commune-geoG h1 
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.commune-geoD
{
    display: flex;
    height: 500px;
    width: 50%;
}

.commune-geoD img
{
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.commune-histoire
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 600px;
    width: 100%;
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url(../../Image/histoire.jpg);
    background-size: cover;
}

.commune-histoire h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
}

.commune-histoire p 
{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: white;
}

.commune-guerre
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: gainsboro;
    height: 900px;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}

.commune-guerre-cadre
{
    width: 50%;
    height: auto;
    display: flex;
}

.commune-guerre-cadre-gauche 
{
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    color: royalblue;
    border-color: black;
    align-items: center;
    background-color: white;
}

.commune-guerre-cadre-gauche img
{
    width: 100%;
    height: auto;
}

.commune-guerre-cadre-droite
{
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    color: royalblue;
    border-color: black;
    align-items: center;
    background-color: white;
}

.commune-guerre-cadre-droite img
{
    width: 100%;
    height: auto;
}

.commune-guerre-cadre button
{
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    font: 14px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 200px;
    border-radius: 2px;
    margin-bottom: 20px;
}

.commune-maire
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    height: 500px;
    width: 100%;
    background: url(../../Image/imagefond.jpg);
}

.commune-maire h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
}

.commune-maire p
{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: white;
}

.commune-maire h2
{
    margin-top: 30px;
    color: white;
    text-transform: uppercase;
}

.commune-maire img
{
    width: 10%;
    margin-top: 20px;
    border-radius: 20px;
}

.commune-partie7
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.commune-partie7 h1
{
    color: royalblue;
    text-transform: uppercase;
    font-style: italic;
}

.commune-club
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 100px;
    width: 100%;
    color: royalblue;
}

.commune-club h4
{
    color: royalblue;
    cursor: pointer;
    padding: 20px;
}

.commune-para
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-bottom:5%;
}

.assoc 
{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    margin-left:5%;
    margin-right:5%;
    width:100%;
    height:auto;
}

.assoc p h1 
{
    text-align:center;
}

.assoc img
{
    width:30%;
    height:auto;
}

/* Téléphone Portable */
@media screen and (max-width: 600px)
{
    .commune-geo
    {
        flex-direction:column;
        height:auto;
    }

    .commune-geoG
    {
        width:80%;
        height:auto;
    }

    .commune-maire p
    {
        font-size:12px;
        text-align: center;
    }

    .commune-geoG h1
    {
        text-align: center;
    }

    .commune-geoD
    {
        width:100%;
        height:auto;
    }

    .commune-geoG h1
    {
        text-align: center;
    }

    .commune-histoire
    {
        width:100%;
        flex-direction:column;
        height:auto;
    }

    .commune-histoire h1
    {
        text-align: center;
    }

    .commune-histoire p
    {
        text-align: center;
        margin-left:2%;
        margin-right:2%;
    }

    .commune-guerre
    {
        height:auto;
    }

    .commune-guerre-cadre
    {
        margin-top:10%;
        width:80%;
        height:auto;
        align-items:center;
        flex-direction:column;
    }

    .commune-guerre-cadre-gauche
    {
        width:80%;
        height:auto;
    }

    .commune-guerre-cadre-gauche h1
    {
        font-size:18px;
        text-align:center;
    }

    .commune-guerre-cadre-droite
    {
        width:80%;
        height:auto;
    }

    .commune-guerre-cadre-droite h1
    {
        font-size:18px;
        text-align:center;
    }

    .commune-para
    {
        height:auto;
    }

    .commune-club
    {
        height:auto;
        flex-direction:column;
    }

    .assoc
    {
        margin-bottom:10%;
    }

}

/*Tablette */
@media screen and (min-width:600px) and (max-width: 1200px)
{
    .commune-geo
    {
        flex-direction:column;
        height:auto;
    }

    .commune-geoG
    {
        width:80%;
        height:auto;
    }

    .commune-geoG h1
    {
        text-align: center;
    }

    .commune-geoD
    {
        width:100%;
        height:auto;
    }

    .commune-geoG h1
    {
        text-align: center;
    }

    .commune-histoire
    {
        width:100%;
        flex-direction:column;
        height:auto;
    }

    .commune-histoire h1
    {
        text-align: center;
    }

    .commune-histoire p
    {
        text-align: center;
        margin-left:2%;
        margin-right:2%;
    }

    .commune-guerre
    {
        height:auto;
    }

    .commune-guerre-cadre
    {
        margin-top:10%;
        width:80%;
        height:auto;
        align-items:center;
        flex-direction:column;
    }

    .commune-guerre-cadre-gauche
    {
        width:80%;
        height:auto;
    }

    .commune-guerre-cadre-gauche h1
    {
        font-size:18px;
        text-align:center;
    }

    .commune-guerre-cadre-droite
    {
        width:80%;
        height:auto;
    }

    .commune-guerre-cadre-droite h1
    {
        font-size:18px;
        text-align:center;
    }

    .commune-para
    {
        height:auto;
    }

    .commune-club
    {
        height:auto;
        flex-direction:column;
    }

    .assoc
    {
        margin-bottom:10%;
    }
}
