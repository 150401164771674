#textesadd
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:auto;
}

.textesadd-titre {

    display:flex;
    width:100%;
    height:125px;
    align-items:center;
    justify-content:center;
    background-color:#048b9a;
    color:white;
    text-transform:uppercase;
}

.textesadd-data {
    display:flex;
    width:100%;
    height:auto;
}

.textesadd-data-gauche {
    display:flex;
    width:15%;
    height:100%;
    flex-direction:column;
    background-color:whitesmoke;
}

.textesadd-data-gauche-entete {
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:60px;
    background-color:#f1c85f;
    color:white;
}

.textesadd-data-gauche-menu {
    display:flex;
    margin-top:20px;
    flex-direction:column;
    align-items:center;
    width:100%;
    height:100%;
}

.textesadd-data-gauche-menu-lien {
    font-weight:bold;
    font-size:13px;
    width:70%;
    margin-top:10px;
    text-transform:uppercase;
    height:30px;
    border-radius:5%;
    padding:15px;
    text-decoration:none;
    color:white;
    background-color:#048b9a;
    cursor:pointer;
}

.textesadd-data-droite {
    display:flex;
    flex-direction:column;
    width:85%;
    height:auto;
}

.textesadd-form-titre
{
    display:flex;
    align-items:center;
    justify-content:center;
    color:white;
    height:60px;
    background-color:#f1c85f;
}

.textesadd-form-data
{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    width:100%;
    height:auto;
}

.textesadd-form-data select
{
    width:60%;
    height:50px;
}

.textesadd-form-data input
{
    width:60%;
    height:50px;
}

.textesadd-form-data textarea
{
    width:60%;
    height:150px;
    margin-bottom:2%;
    white-space:pre;
}

.textesadd-form-data-image 
{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    width:100%;
    height:auto;
}

.textesadd-form-data-image input
{
    width:50%;
}

.textesadd-form-data-image button
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:50%;
    height:40px;
    color:white;
    background-color:#048b9a;
    text-transform:uppercase;
    border-radius:5px;
    border-color:#048b9a;
    cursor:pointer;
}


.textesadd-photosres
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    height:auto;
}

.textesadd-photosres img
{
    width:50%;
    height:200px;
}

.textesadd-form-submit
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    height:150px;
}

.textesadd-form-submit button
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:50%;
    height:40px;
    color:white;
    background-color:#048b9a;
    text-transform:uppercase;
    border-radius:5px;
    border-color:#048b9a;
    cursor:pointer;
}

.textesadd-data-droite-titre
{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:60px;
    background-color: #f1c85f;
    color:white;
}


.textesadd-data-droite-form 
{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    width:100%;
    height:auto;
}

.textesadd-data-droite-form  select
{
    width:60%;
    height:50px;
}

.textesadd-data-droite-form  input
{
    width:60%;
    height:50px;
}

.textesadd-data-droite-form  textarea
{
    width:60%;
    height:150px;
    margin-bottom:2%;
    white-space:pre;
}

.textesadd-data-droite-form-submit 
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    height:150px;
}

.textesadd-data-droite-form-submit button
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:50%;
    height:40px;
    color:white;
    background-color:#048b9a;
    text-transform:uppercase;
    border-radius:5px;
    border-color:#048b9a;
    cursor:pointer;
}