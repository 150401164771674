#photosadd
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:95%;
}

.photosadd-titre {

    display:flex;
    width:100%;
    height:125px;
    align-items:center;
    justify-content:center;
    background-color:#048b9a;
    color:white;
    text-transform:uppercase;
}

.photosadd-data {
    display:flex;
    width:100%;
    height:100%;
}

.photosadd-data-gauche {
    display:flex;
    width:15%;
    height:100%;
    flex-direction:column;
    background-color:whitesmoke;
}

.photosadd-data-gauche-entete {
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:60px;
    background-color:#f1c85f;
    color:white;
}

.photosadd-data-gauche-menu {
    display:flex;
    margin-top:20px;
    flex-direction:column;
    align-items:center;
    width:100%;
    height:100%;
}

.photosadd-data-gauche-menu-lien {
    font-weight:bold;
    font-size:13px;
    width:70%;
    margin-top:10px;
    text-transform:uppercase;
    height:30px;
    border-radius:5%;
    padding:15px;
    text-decoration:none;
    color:white;
    background-color:#048b9a;
    cursor:pointer;
}

.photosadd-data-droite {
    display:flex;
    flex-direction:column;
    width:85%;
    height:100%;
}

.photosadd-data-droite-titre
{
    display:flex;
    align-items:center;
    justify-content:center;
    color:white;
    height:60px;
    background-color:#f1c85f;
}

.photosadd-data-droite-photosadd
{
    display:flex;
    width:100%;
    height:auto;
    flex-direction:column;
}

.photosadd-diaporamaccueil-data
{
    display:flex;
    width:100%;
    height:auto;
}