/*Diaporama */
#Diaporama
{
    display:flex;
    position:relative;
    width:100%;
    height:600px;
    overflow:hidden;
    background-color:#f0c300;
}

.diaporama-fleches-prec
{
    position:absolute;
    width:15px;
    height:15px;
    top:55%;
    left:50px;
    transform :rotate(180deg);
    cursor:pointer;
    z-index:5;
}

.diaporama-fleches-suiv
{
    position:absolute;
    width:15px;
    height:15px;
    top:55%;
    right:50px;
    cursor:pointer;
    z-index:5;
}

.slider
{
    display:flex;
    height:auto;
}

.diapos
{
    display:flex;
    position:relative;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    height:auto;
    font-size:30px;
    color:black;
    z-index:2;
    line-height:10px;
}

.diapos-titre
{
    position:absolute;
    top:10px;
    left:10px;
    font-size:30px;
}

.diapos-soustitre
{
    position:absolute;
    top:60px;
    left:10px;
    font-size:15px;
}

.diapos img
{
    object-fit:contain;
    height:auto;
}

@media screen and (max-width: 600px)
{
    #Diaporama
    {
        height:auto;
    }
}