.menu-racine
{
    display:flex;
    flex-direction:column;
    height: auto;
    width: 100%;
    font-family: "Roboto-Bold";
}

.menu-data
{
    display: flex;
    height: 45px;
    width: 100%;
    background-color: white;
}

.menu-gauche
{
    display:flex;
    width:40%;
    align-items:center;
    justify-content: space-between;
    margin-left:10%;
}

.menu-centre
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:50%;
}

.menu-centre img
{
    width: 35px;
    height: 35px;
    margin-top: 2px;
    margin-right: 60px;
    margin-left: 60px;
}

.menu-droite
{
    display:flex;
    width:40%;
    align-items:center;
    justify-content: space-between;
    margin-right:10%;
}

.menu-part
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    height: 15px;
   
}

.menu-lien
{
    text-transform: uppercase;
    font-size: 15px;
    text-decoration:none;
    font-weight:bold;
    color:royalblue;
}

.sousmenu-part
{
    display:flex;
    align-items:center;
    justify-content: center;
    position:absolute;
    top:50px;
    left:25%;
    width:200px;
    height:80px;
    flex-direction:column;
    background-color:black;
    z-index:10;
    border:1px solid white;
}

.sousmenu-section
{
    display:flex;
    width:100%;
    height:100%;
    align-items:center;
    justify-content:center;
}

.sousmenu-lien
{
    display:flex;
    width:100%;
    align-items:center;
    justify-content:center;
    color:white;
    text-align:center;
    height:25px;
    width:200px;
    font-size:12px;
    text-transform:uppercase;
    text-decoration:none;
}