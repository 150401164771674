.PhotosLigne
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
}

.PhotosLigne img
{
    width:100%;
    height:50%;
}