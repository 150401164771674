#Login
{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:auto;
    
    background-color:whitesmoke;
}

.login-cadre
{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:450px;
    height:450px;
    font-family: 'Alex Brush', arial;
    font-size:30px;
    background-color: royalblue;
    margin-top:5%;
    margin-bottom:5%;
    border-radius:5px;
}

.login-cadre h3
{
    color:white;
}

.login-cadre-logo
{
    width:25%;
    height:auto;
    object-fit:cover;
    margin-bottom:40px;
    border-radius:5px;
}

.login-cadre-user
{
    width:60%;
    height:25px;
    margin-bottom:20px;
}

.login-cadre-pwd
{
    width:60%;
    height:25px;
    margin-bottom:20px;
}

.login-cadre-bouton
{
    background-color:yellow;
    color:royalblue;
    border:1px solid yellow;
    border-radius:5px;
    margin-top:10px;
    width:70%;
    height:35px;
    text-transform:uppercase;
    font-weight:bold;
    cursor:pointer;
}