.Cookies
{
    display:none;
    align-items: center;
    justify-content: center;
    top:45%;
    left:0%;
    width:100%;
    background-color:royalblue;
    z-index:60;
}

.cookies-cadre
{
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    width:100%;
    height:100%;
    cursor:pointer;
    position:relative;
}

.cookies-cadre p 
{
    width:100%;
    height:100%;
    font-size:13px;
    color:white;
    text-align:center;
    text-orientation: mixed;
    writing-mode: vertical-rl;
}

@media screen and (max-width: 600px)
{
    .Cookies
    {
        display:flex;
        flex-direction:row;
        left:auto;
        right:auto;
        bottom:0;
    }

    .cookies-cadre
    {
        display:flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
        width:100%;
        height:100%;
        cursor:pointer;
        position:relative;
    }

    .cookies-cadre p 
    {
        width:100%;
        height:100%;
        font-size:13px;
        color:white;
        text-align:center;
        text-orientation: mixed;
        writing-mode: sideways-lr;
    }
    
}