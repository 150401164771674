.Accueil
{
    display:flex;
    flex-direction:column;
    height:auto;
    width:100%;
}

.accueil-entete
{
    display: flex;
    height: 300px;
    z-index: 2;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: white;
    color: royalblue;
    width: 100%;
    list-style: none;
}

.accueil-entete h1 
{
    text-decoration: none;
    text-transform: uppercase;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-right: 10px;
}

.accueil-entete img
{
    width: 35px;
    height: 35px;
    margin-top: 2px;
    margin-right: 60px;
    margin-left: 60px;
}

.accueil-front
{
    display: flex;
    width: 100%;
    overflow: hidden;
}

.accueil-frontG
{
    width: 680px;
    height: 500px;
    object-fit: cover;
}

.accueil-frontD
{
    width: 680px;
    height: 500px;
    object-fit: cover;
}

.accueil-bienvenue
{
    display: flex;
    width: 100%;
    height: 150px;
    align-items: center;
    justify-content: center;
    color: royalblue;
    text-align: center;
    background-color: white;
    box-shadow: 5px 5px 20px;
}

.accueil-une
{
    display:flex;
    flex-direction:column;
    width: 100%;
    height: auto;
    color: royalblue;
    text-align: center;
    margin-bottom: 20px;
}

.accueil-une-titre
{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:150px;
}

.accueil-une-data
{
    display:flex;
    width:100%;
    height:500px;
    align-items:center;
    justify-content:center;
}

.accueil-une-data-gauche
{
    display:flex;
    width:55%;
    height:500px;
}

.accueil-une-data-gauche img
{
    width:100%;
    object-fit:cover;
    height:auto;
}

.accueil-une-data-droite
{
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    height:500px;
    width:25%;
    background-color:royalblue;
    color:white;
}

.accueil-une-data-droite p
{
    text-align:center;
}

.accueil-une-data-droite a
{
    display:flex;
    align-items:center;
    justify-content: center;
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    font: 11px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 200px;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
}

.accueil-une-data-droite button
{
    display:flex;
    align-items:center;
    justify-content: center;
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    font: 11px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 200px;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
}

.accueil-actu
{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    width:100%;
    height:auto;
}

.accueil-actu-haut
{
    display:flex;
    align-items:center;
    justify-content:center;
    color:royalblue;
    width:100%;
    height:100px;
}

.accueil-actu-bas
{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content:center;
    height: auto;
}

.accueil-actu-bas-cadre
{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: lightgrey;
    width: 20%;
    height: 500px;
    margin:2%;
    color: royalblue;
    border-color: black;
    border-radius:8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.accueil-actu-bas-cadre img 
{
    width: 100%;
    height: 55%;
    object-fit: cover;
    border-radius: 5px;
}

.accueil-actu-bas-cadre h1 
{
    font-size:15px;
    text-align:center;
}

.accueil-actu-bas-cadre p 
{
    font-size:12px;
    text-align:center;
}

.accueil-actu-bas-cadre a
{
    display:flex;
    align-items:center;
    justify-content: center;
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    margin-bottom:2%;
    font: 11px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 25px;
    width: 200px;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
}

.accueil-conseil
{
    display: flex;
    width: 100%;
    height: 800px;
    background-color: gainsboro;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.accueil-conseil img
{
    width: 40%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
}

.accueil-conseil h1
{
    width: 90%;
    height: auto;
    text-align: center; 
}

.accueil-conseil a 
{
    cursor: pointer;
    text-decoration: underline;
}

.accueil-conseil button
{
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:10px;
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    font: 14px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 20px;
    width: 200px;
    border-radius: 2px;
    padding: 7px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.accueil-municipal
{
    display:flex;
    position:relative;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    height: auto;
}

.accueil-municipal img
{
    width:100%;
    object-fit: cover;
    object-position: center;
    height:700px;
}

.accueil-municipal-titre
{
    display:flex;
    width:100%;
    height:60px;
    align-items:center;
    justify-content: center;
    color:white;
    background-color: royalblue;
}

.accueil-avis
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: auto;
    padding-top:2%;
    padding-bottom:2%;
    color: royalblue;
    box-shadow: 5px 5px 20px;
}

.accueil-avisG
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 33%;
    height: auto;
    color: royalblue;
}

.accueil-avisG img
{
    width: 45%;
    cursor:pointer;
    height:auto;
}

.accueil-avisM
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 33%;
    height: auto;
    color: royalblue;
}

.accueil-avisM-lien
{
    display:flex;
    width:100%;
    flex-direction:column;
}

.accueil-avisM img
{
    width: 20%;
    cursor:pointer;
    height:auto;
}

.accueil-avisD
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 33%;
    height: auto;
    color: royalblue;
}

.accueil-avisD img
{
    width: 20%;
    cursor:pointer;
    height:auto;
}

.accueil-events
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:auto;
}

.accueil-events-titre
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    height:auto;
    margin-top:2%;
    margin-bottom:2%;
    color:royalblue;
}

.accueil-events-data
{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows:auto;
    margin-bottom:5%;
}

.accueil-events-cadre
{   
    display:flex;
    flex-direction:column;
    width:90%;
    height:auto;
    margin-top:4%;
    margin-bottom:4%;
    margin-left:5%;
    margin-right:5%;
}

.accueil-events-cadre-titre
{   
    display:flex;
    align-items:center;
    justify-content: center;
    background-color:royalblue;
    color:white;
    width:100%;
    height:auto;
}

.accueil-events-cadre-data
{
    display:flex;
    width:100%;
    height:auto;
}

.accueil-events-cadre-data img
{
    width:100%;
    height:auto;
}

/* Téléphone Portable */
@media screen and (max-width: 600px)
{  
    .accueil-bienvenue 
    {
        height:90px;
    }
    
    .accueil-bienvenue h1
    {
        font-size:20px;
    }

    .accueil-actu
    {
        display:flex;
        align-items:center;
        justify-content: center;
    }

    .accueil-une-data
    {
        flex-direction:column;
        height:auto;
        width:100%;
    }
    
    .accueil-municipal img
    {
        display:none;
    }

    .accueil-une-data-gauche
    {
        width:100%;
        height:auto;
        align-items:center;
        justify-content:center;
    }

    .accueil-une-data-gauche img
    {
        width:100%;
        height:auto;
    }

    .accueil-une-data-droite
    {
        width:100%;
        height:auto;
        justify-content:center;
        flex-direction:column;
        padding-bottom:5%;
    }

    .accueil-actu-haut
    {
        flex-direction:column;
    }

    .accueil-actu-haut h1
    {
        font-size:14px;
    }

    .accueil-actu-bas
    {
        width:100%;
        align-items:center;
        flex-direction:column;       
        height:auto;
    }

    .accueil-actu-bas-cadre
    {
        margin-top:5%;
        margin-bottom:5%;
        width:90%;
        height:600px;
    }

    .accueil-municipal 
    {
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .accueil-avis
    {
        display:flex;
        flex-direction:column;
        height:auto;
    }

    .accueil-avisG
    {
        display:flex;
        width:100%;
        height:auto;
        margin-top:5%;
    }

    .accueil-avisG img
    {
        width:80%;
    }

    .accueil-avisG h1
    {
        text-align:center;
        font-size:16px;
    }

    .accueil-avisM
    {
        display:flex;
        width:100%;
        height:auto;
    }

    .accueil-avisM img
    {
        width:30%;
    }

    .accueil-avisM h1
    {
        text-align:center;
        font-size:16px;
    }

    .accueil-avisD
    {
        display:flex;
        width:100%;
        height:auto;
        margin-bottom:10%;
    }

    .accueil-avisD img
    {
        width:30%;
    }

    .accueil-avisD h1
    {
        text-align:center;
        font-size:16px;
    }

    .accueil-events-data
    {
        display:flex;
        flex-direction:column;
    }
}

/*Tablette */
@media screen and (min-width:600px) and (max-width: 1200px)
{
    .accueil-une-data
    {
        flex-direction:column;
        height:auto;
        width:100%;
    }

    .accueil-une-data-gauche
    {
        width:100%;
        height:auto;
        align-items:center;
        justify-content:center;
    }

    .accueil-une-data-gauche img
    {
        width:100%;
        height:auto;
    }

    .accueil-une-data-droite
    {
        width:100%;
        height:auto;
        justify-content:center;
        flex-direction:column;
    }

    .accueil-une-data-droite a
    {
        margin-bottom:5%;
    }

    .accueil-actu-haut
    {
        flex-direction:column;
    }

    .accueil-actu-haut h1
    {
        font-size:20px;
    }

    .accueil-actu-bas
    {
        width:100%;
        align-items: center;
        height:auto;
        flex-direction:column;       
        height:auto;
    }

    .accueil-actu-bas-cadre
    {
        margin-top:5%;
        margin-bottom:5%;
        width:50%;
        height:auto;
    }

    .accueil-municipal 
    {
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .accueil-avis
    {
        display:flex;
        flex-direction:column;
        height:auto;
    }

    .accueil-avisG
    {
        display:flex;
        width:100%;
        height:auto;
        margin-top:2%;
        margin-bottom:2%;
    }

    .accueil-avisG img
    {
        width:50%;
    }

    .accueil-avisG h1
    {
        text-align:center;
        font-size:16px;
    }

    .accueil-avisM
    {
        display:flex;
        width:100%;
        height:auto;
        margin-top:2%;
        margin-bottom:2%;
    }

    .accueil-avisM img
    {
        width:20%;
    }

    .accueil-avisM h1
    {
        text-align:center;
        font-size:16px;
    }

    .accueil-avisD
    {
        display:flex;
        width:100%;
        height:auto;
        margin-top:5%;
        justify-content: center;
        margin-bottom:5%;
    }

    .accueil-avisD img
    {
        width:20%;
    }

    .accueil-avisD h1
    {
        text-align:center;
        font-size:16px;
    }

    .accueil-events-data
    {
        display:flex;
        flex-direction:column;
    }
}