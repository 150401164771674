.users-data-list-ligne-show
{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows:auto; 
    background-color:white;
    color:black;
    text-align:center;
    border-bottom: 1px dotted black;
}
.users-data-list-ligne-show-icon
{
    display:flex;
    width:100%;
    align-items:center;
    justify-content:center;
}
.users-data-list-ligne-show-image
{
    width:10%;
    cursor:pointer;
    padding:10px;
}
.users-data-list-ligne-edit
{
    display:none;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows:auto; 
    background-color:white;
    color:black;
    text-align:center;
}
.users-data-list-ligne-edit-icon
{
    display:flex;
    width:100%;
    align-items:center;
    justify-content:center;
}
.users-data-list-ligne-edit-image
{
    width:30%;
    cursor:pointer;
    padding:10px;
}

.users-list-pwd
{
    font-size:2px;
}