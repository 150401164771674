#MenuAdmin
{
    display:flex;
    align-items:center;
    justify-content:space-around;
    width:100%;
    height:5%;
    background-color:lightslategrey;
}

.menu-admin-lien
{
    display:flex;
    flex-direction:column;
    justify-content:center;
    width:100%;
    height:auto;
    align-items:center;
    text-decoration:none;
    color:white;
    text-transform:uppercase;
}

.menu-lien-logo
{
    margin-top:5px;
    width:20%;
    height:30px;
}