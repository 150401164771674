.Demarches
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:auto;
}

.demarche-titre
{   
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    color: royalblue;
    text-align: center;
    text-transform:uppercase;
    background-color: white;
    box-shadow: 5px 5px 20px;
}

.demarche-classeur
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    height: 500px;
    width: 100%;
    text-transform: uppercase;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),url(../../Image/classeur.jpg);
    background-position: right;
    background-attachment: fixed;
}

.demarche-carte
{
    height: 500px;
    width: 100%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.demarche-carteG
{
    height: 500px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: whitesmoke;
    color: royalblue;
}

.demarche-carteG h1 
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: royalblue;
}

.demarche-carteG img
{
    color: royalblue;
    width: 30%;
    text-transform: uppercase;
    border-radius: 5px;
    margin-bottom: 20px;
}


.demarche-carteG button
{
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    font: 14px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 200px;
    border-radius: 2px;
    margin-top: 20px;
}

.demarche-carteG h2
{
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: royalblue;
    text-transform: uppercase;
}

.demarche-carteD
{
    width: 30%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: royalblue;
    color: white;
}

.demarche-carteD h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.demarche-carteD img
{
    border-radius: 5px;
    margin-top: 20px;
}

.demarche-carteD h2
{
    margin-top: 50px;
    text-transform: uppercase;
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.demarche-partie7
{
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.demarche-partie7-gauche
{
    height: 500px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: gainsboro;
}

.demarche-partie7-gauche h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: royalblue;
}

.demarche-partie7-gauche img
{
    width: 30%;
    margin-bottom: 20px;  
}

.demarche-partie7-gauche button
{
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    font: 14px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 200px;
    border-radius: 2px;
    margin-top: 20px;
}

.demarche-partie7-gauche h2
{
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: royalblue;
    text-transform: uppercase;
}

.demarche-partie7-droite
{
    height: 500px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: whitesmoke;
    color: white;
}

.demarche-partie7-droite h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: royalblue;
}

.demarche-partie7-droite img
{
    width: 20%;
    margin-bottom: 30px;
}

.demarche-partie7-droite button
{
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    font: 14px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 200px;
    border-radius: 2px;
    margin-top: 20px;
}

.demarche-partie7-droite h2
{
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold 
}

.demarche-election
{
    height: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ),url(../../Image/voter.jpg);
    background-size: cover;
    background-position: fixed;
    background-attachment: fixed;
}

.demarche-election h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
    font-size: 50px;
    font-weight: 900;
    text-transform: uppercase;
}

.demarche-inscription
{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color:royalblue;
    height: 100px;
    text-transform: uppercase;
    box-shadow: 5px 5px 20px;
}

.demarche-inscription h1
{
    text-transform:uppercase;
}

.demarche-partie9
{
    display: flex;
    height: 600px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: white;
    width: 100%;
    margin-top:2%;
    margin-bottom:2%;
}

.demarche-partie9-gauche
{
    display: flex;
    height: 600px;
    width: 20%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: royalblue;
}

.demarche-partie9-gauche h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
    width: 60%;
}

.demarche-partie9-gauche img 
{
    width: 40%;
}

.demarche-partie9-milieu
{
    display: flex;
    height: 600px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background-color: white;
    width: 80%;
}

.demarche-partie9-milieu-cadre
{
    display: flex;
    background-color: whitesmoke;
    width: 25%;
    height: 450px;
    color: royalblue;
    border-color: black;
    align-items: center;
    flex-direction: column;
    margin-right: 30px;
    margin-left: 30px;
    box-shadow: 2px 2px 2px black;
    padding-bottom: 10px;
}

.demarche-partie9-milieu-cadre img
{
    width: 100%;
    height: 50%;
    object-fit: cover;
    border-radius: 5px;
}

.demarche-partie9-milieu-cadre h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    width: 90%;
    height: 10%;
    text-align: center;
}

.demarche-partie9-milieu-cadre p
{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    width: 90%;
    height: 10%;
    margin-top: 5px;
    text-align: center;
} 

.demarche-partie9-milieu-cadre button 
{
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    font: 14px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 200px;
    border-radius: 2px;
    margin-top: 20px;
}

.demarche-partie9-droite
{
    display: flex;
    height:600px;
    justify-content: right;
    align-items: center;
    width: 20%;
    background-color: royalblue;
    flex-direction: column;
    color: white;
}

.demarche-partie9-droite h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: white;
    text-align: center;
}


.demarche-partie9-droite p
{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: white;
    text-align: center;
}

.demarche-partie9-droite button
{
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    font: 14px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 200px;
    border-radius: 2px;
    margin-top: 20px;
} 

.demarche-piece
{
    display: flex;
    justify-content: center;
    align-items: center;
    color: royalblue;
    background-color: white;
    height: 100px;
    width: 100%;
    text-transform: uppercase;
    box-shadow: 5px 5px 20px;
}

.demarche-piece h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.demarche-partie10
{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    width: 100%;
}

.demarche-partie10-cadre
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 450px;
    width: 20%;
    border-radius: 15px;
    border-style: solid;
    border-width: 2px;
    border-color: royalblue;
    margin: 10px;
    color: white;
    background-color: royalblue;
}


.demarche-partie10-cadre h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}


.demarche-partie10-cadre img
{
    width: 20%;
    padding: 50px;
}

.demarche-partie10-cadre button
{
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    font: 14px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 200px;
    border-radius: 2px;
    margin-top: 20px;
}

.demarche-cuisine
{
    display: flex;
    width: 500%;
    height: 500px;
}

.demarche-cuisine1
{
    width: 34%;
    height: 500px;
    object-fit: cover;
}

.demarche-cuisine2
{
    width: 34%;
    height: 500px;
    object-fit: cover;
}


.demarche-cuisine3
{
    width: 34%;
    height: 500px;
    object-fit: cover;
}

.demarche-cuisine1 img 
{
    width: 34%;
    height: 500px;
    object-fit: cover;
}

.demarche-cuisine2 img 
{
    width: 34%;
    height: 500px;
    object-fit: cover;
}

.demarche-cuisine3 img 
{
    width: 34%;
    height: 500px;
    object-fit: cover;
}

.demarche-louer
{
    display: flex;
    justify-content: center;
    align-items: center;
    color: royalblue;
    background-color: white;
    height: 100px;
    width: 100%;
    text-transform: uppercase;
    box-shadow: 5px 5px 20px;
}

.demarche-louer h1
{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.demarche-form-reserv
{
    display:flex;
    width:100%;
    height:auto;
    margin-bottom:2%;
    align-items:center;
    flex-direction:column;
}

.demarche-form-reserv-titre
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    height:100px;
}

.demarche-form-reserv-titre h1
{
    text-transform:uppercase;
    color:royalblue;
}

.demarche-form-reserv-data
{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    width:100%;
    height:auto;
}

.demarche-form-reserv-data input
{
    width:50%;
}

.demarche-form-reserv-data textarea
{
    width:50%;
    height:150px;
}

.demarche-form-reserv-submit
{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:100px;
}

.demarche-form-reserv-submit button
{
    display:flex;
    align-items:center;
    justify-content: center;
    color: royalblue;
    background-color: yellow;
    border-color: black;
    border-width: 0;
    font: 14px Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    height: 35px;
    width: 60%;
    border-radius: 2px;
    margin-top: 10px;
    margin-right: 20px;
    cursor: pointer;
}

/* Téléphone Portable */
@media screen and (max-width: 600px)
{
    .demarche-classeur h1
    {
        text-align:center;
    }

    .demarche-carte
    {
        flex-direction:column;
        height:auto;
        margin-top:0;
        margin-bottom:0;
    }

    .demarche-carteG
    {
        width:100%;
        height:auto;
        padding-top:5%;
        padding-bottom:5%;
    }

    .demarche-carteD
    {
        width:100%;
        height:auto;
        padding-top:5%;
        padding-bottom:5%;
    }

    .demarche-partie7
    {
        flex-direction:column;
        height:auto;
        margin:0;
    }

    .demarche-partie7-gauche
    {
        width:100%;
        height:auto;
        padding-top:5%;
        padding-bottom:5%;
    }

    .demarche-partie7-droite
    {
        width:100%;
        height:auto;
        padding-top:5%;
        padding-bottom:5%;
       
    }

    .demarche-partie9
    {
        display:flex;
        height:auto;
        flex-direction:column;
    }

    .demarche-partie9-gauche
    {
        width:100%;
        display:none;
    }

    .demarche-partie9-droite
    {
        height:auto;
        padding-bottom:2%;
    }

    .demarche-partie9-milieu
    {
        flex-direction:column;
        height:auto;
    }

    .demarche-partie9-milieu-cadre
    {
        width:100%;
        height:auto;
        margin-top:2%;
        margin-bottom:2%;
    }

    .demarche-piece
    {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    }

    .demarche-partie9-droite
    {
        width:100%;
        margin:0;
    }

    .demarche-partie10
    {
        height:auto;
        flex-direction:column;
    }

    .demarche-partie10-cadre
    {
        width:80%;
        height:auto;
        margin-top:2%;
        margin-bottom:2%;
        padding-bottom:5%;
        padding-top:5%;
    }

    .demarche-form-reserv-titre
    {
        display:flex;
        align-items: center;
        justify-content: center;
    }

    .demarche-form-reserv-titre
    {
        text-align: center;
    }
}

/* Tablette */
@media screen and (min-width:600px) and (max-width: 1200px)
{
     .demarche-classeur h1
    {
        text-align:center;
    }

    .demarche-carte
    {
        flex-direction:column;
        height:auto;
        margin-top:0;
        margin-bottom:0;
    }

    .demarche-carteG
    {
        width:100%;
        height:auto;
    }

    .demarche-carteD
    {
        width:100%;
        height:auto;
    }

    .demarche-partie7
    {
        flex-direction:column;
        height:auto;
        margin:0;
    }

    .demarche-partie7-gauche
    {
        width:100%;
        align-items:center;
        height:auto;
    }

    .demarche-partie7-droite
    {
        width:100%;
        height:auto;
    }

    .demarche-partie9
    {
        display:flex;
        height:auto;
        width:100%;
        flex-direction:column;
        align-items: center;
        margin:0;
    }

    .demarche-partie9-gauche
    {
        display:none;
        justify-content: center;
        width:100%;
        height:auto;
        align-items:center;
    }

    .demarche-partie9-gauche img
    {
        width:10%;
    }

    .demarche-partie9-milieu
    {
        flex-direction:column;
        height:auto;
    }

    .demarche-partie9-milieu-cadre
    {
        width:50%;
        height:auto;
        margin-top:2%;
        margin-bottom:2%;
    }

    .demarche-partie9-droite
    {
        width:100%;
        height:auto;
        padding-top:5%;
        padding-bottom:5%;
    }
    
    .demarche-piece
    {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    }

    .demarche-partie10
    {
        height:auto;
        flex-direction:column;
    }

    .demarche-partie10-cadre
    {
        width:50%;
        height:auto;
        margin-top:2%;
        margin-bottom:2%;
    }

}









    
    
    







 





