.MenuMobile {
    display:flex;
    width:100%;
    height:50px;
    position:fixed;
    top:0;
    z-index:10;
}

.menumobile-princ
{
    display:flex;
    width:100%;
    height:50px;
    background-color:white;
}

.menumobile-princ-gauche
{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    margin-left:5%;
    width:50%;
    height:100%;
}

.menumobile-princ-gauche img
{
    width: 15%;
    height:auto;
}

.menumobile-princ-droite
{
    display:flex;
    align-items:center;
    justify-content:flex-end;
    width:50%;
    height:100%;
    margin-right:5%;
}

.menumobile-princ-droite img
{
    cursor:pointer;
    width:20%;
    height:auto;
}

@media screen and (min-width:600px) and (max-width: 1200px)
{
    .menumobile-princ-droite img
    {
        width:10%;
    }

    .menumobile-princ-gauche img
    {
        width:10%;
    }
}