#Users
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
}

.users-titre {

    display:flex;
    width:100%;
    height:125px;
    align-items:center;
    justify-content:center;
    background-color:#048b9a;
    color:white;
    text-transform:uppercase;
}

.users-data {
    display:flex;
    width:100%;
    height:100%;
}

.users-data-gauche {
    display:flex;
    width:15%;
    height:100%;
    flex-direction:column;
    background-color:whitesmoke;
}

.users-data-gauche-entete {
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:60px;
    background-color:#f1c85f;
    color:white;
}

.users-data-gauche-menu {
    display:flex;
    margin-top:20px;
    flex-direction:column;
    align-items:center;
    width:100%;
    height:100%;
}

.users-data-gauche-menu-lien {
    font-weight:bold;
    font-size:13px;
    width:70%;
    margin-top:10px;
    text-transform:uppercase;
    height:30px;
    border-radius:5%;
    padding:15px;
    text-decoration:none;
    color:white;
    background-color:#048b9a;
    cursor:pointer;
}

.users-data-droite {
    display:flex;
    flex-direction:column;
    width:85%;
    height:100%;
}

.users-data-droite-entete {
    display:grid;
    align-items:center;
    text-align:center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows:auto;
    background-color:#f1c85f;
    height:60px;
    color:white;
    text-transform:uppercase;
}

.users-data-droite-list {
   width:100%;
   height:95%;
}

.users-data-droite-list-ligne {
    display:grid;
    align-items:center;
    text-align:center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows:auto;
    height:30px;
}

.users-data-droite-list-ligne-pwd
{
    font-size:6px;
}

.users-data-droite-list-ligne-action {
    display:flex;
    width:100%;
    height:auto;
    align-items:center;
    justify-content:center;
}

.users-edit-bouton {
    width:10%;
    margin-right:10px;
    cursor:pointer;
}

.users-delete-bouton {
    width:10%;
    margin-left:10px;
    cursor:pointer;
}