.SousMenuMobile
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:auto;
    position:relative;
    align-items:center;
    z-index:10;
}

.sousmenumobile-princ
{
    display:flex;
    position:absolute;
    top:50px;
    background-color: white;
    flex-direction:column;
    align-items:center;
    width:90%;
    height:auto;
    padding-top : 2%;
    padding-bottom: 2%;
    border:1px solid royalblue;;
}

.sousmenumobile-part
{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
    width:100%;
    height:30px;
}

.sousmenumobile-princ-lien
{
    display:flex;
    width:100%;
    align-items:center;
    justify-content:center;
    text-decoration:none;
    text-transform:uppercase;
    color:royalblue;
    margin-top:1%;
    cursor:pointer;
}