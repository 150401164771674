#Admin
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
}

.admin-titre {

    display:flex;
    width:100%;
    height:125px;
    align-items:center;
    justify-content:center;
    background-color:#048b9a;
    color:white;
    text-transform:uppercase;
}

.admin-data {
    display:flex;
    width:100%;
    height:100%;
}

.admin-data-gauche {
    display:flex;
    width:15%;
    height:100%;
    flex-direction:column;
    background-color:whitesmoke;
}

.admin-data-gauche-entete {
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:60px;
    background-color:#f1c85f;
    color:white;
}

.admin-data-gauche-menu {
    display:flex;
    margin-top:20px;
    flex-direction:column;
    align-items:center;
    width:100%;
    height:100%;
}

.admin-data-gauche-menu-lien {
    font-weight:bold;
    font-size:13px;
    width:70%;
    margin-top:10px;
    text-transform:uppercase;
    height:30px;
    border-radius:5%;
    padding:15px;
    text-decoration:none;
    color:white;
    background-color:#048b9a;
    cursor:pointer;
}

.admin-data-droite {
    display:flex;
    flex-direction:column;
    width:85%;
    height:100%;
}

.admin-data-droite-entete {
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:60px;
    background-color:#f1c85f;
    color:white;
}

.admin-data-droite-list
{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows:auto;
    justify-content: space-around;
    width:100%;
    height:50%;
}

.admin-data-droite-cadre
{
    display:flex;
    height:300px;
    align-items:center;
    flex-direction:column;
    margin-top:20px;
    position:relative;
    width:80%;
    margin-bottom:2%;
    margin-left:2%;
    margin-right:2%;
    border-radius:8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);   
}

.admin-data-droite-cadre img
{
    width:15%;
    margin-bottom:5%;
}

.admin-data-droite-cadre-bouton-niv1
{
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:bold;
    font-size:12px;
    width:40%;
    text-align:center;
    text-transform:uppercase;
    height:25px;
    border-radius:10px;
    text-decoration:none;
    color:white;
    border:1px solid #048b9a;
    background-color:#048b9a;
    cursor:pointer;
}

.admin-data-droite-cadre-bouton-niv2
{
    display:flex;
    align-items:center;
    justify-content:center;
    font-weight:bold;
    font-size:12px;
    width:20%;
    text-align:center;
    text-transform:uppercase;
    height:25px;
    border-radius:10px;
    text-decoration:none;
    color:white;
    border:1px solid #048b9a;
    background-color:#048b9a;
    cursor:pointer;
    margin-left:10%;
    margin-right:10%;
}


.admin-cadre-bouton
{
    display:flex;
    width:100%;
    justify-content:center;
    height:50px;
    align-items:center;
}