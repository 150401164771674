.Footer
{
    display: flex;
    width: 100%;
    height:auto;
    color: white;    
    background-color:royalblue;
}

.footer-gauche
{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    height:auto;
    width:30%;
}

.footer-gauche img
{
    width:10%;
}

.footer-gauche p
{
    text-align:center;
}

.footer-milieu
{
    display:flex;
    height:auto;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    width:50%;
}

.footer-milieu-liens
{
    text-decoration:none;
    color:white;
    cursor:pointer;
}

.footer-droite
{
    display:flex;
    align-items:center;
    justify-content:center;
    height:auto;
    flex-direction:column;
    width:30%;
}

.footer-droite p
{
    text-align:center;
    
}

.horaire-ligne 
{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 600px)
{
    .Footer
    {
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }

    .footer-gauche
    {
        text-align:center;
    }
}

@media screen and (min-width:600px) and (max-width: 1200px)
{
    .Footer
    {
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }

    .footer-gauche
    {
        text-align:center;
        width:100%;
    }

    .footer-droite
    {
        width:100%;
    }
}