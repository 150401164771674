#Actus
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:auto;
}

.actus-titre {

    display:flex;
    width:100%;
    height:125px;
    align-items:center;
    justify-content:center;
    background-color:#048b9a;
    color:white;
    text-transform:uppercase;
}

.actus-data {
    display:flex;
    width:100%;
    height:auto;
}

.actus-data-gauche {
    display:flex;
    width:15%;
    height:auto;
    flex-direction:column;
    background-color:whitesmoke;
}

.actus-data-gauche-entete {
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:60px;
    background-color:#f1c85f;
    color:white;
}

.actus-data-gauche-menu {
    display:flex;
    margin-top:20px;
    flex-direction:column;
    align-items:center;
    width:100%;
    height:100%;
}

.actus-data-gauche-menu-lien {
    font-weight:bold;
    font-size:13px;
    width:70%;
    margin-top:10px;
    text-transform:uppercase;
    height:30px;
    border-radius:5%;
    padding:15px;
    text-decoration:none;
    color:white;
    background-color:#048b9a;
    cursor:pointer;
}

.actus-data-droite {
    display:flex;
    flex-direction:column;
    width:85%;
    height:100%;
}

.actus-data-droite-titre
{
    display:flex;
    align-items:center;
    justify-content:center;
    color:white;
    height:60px;
    background-color:#f1c85f;
}

.actus-data-droite-actus
{
    display:flex;
    width:100%;
    height:auto;
    flex-direction:column;
}

.actus-diaporamaccueil-data
{
    display:flex;
    width:100%;
    height:auto;
}

.actus-princ
{
    display:flex;
    align-items:center;
    width:100%;
    height:auto;
    flex-direction:column;
    margin-bottom:2%;
}

.actus-princ img
{
    width:30%;
    height:auto;
}

.actus-princ-actions
{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:100px;
}

.actus-princ-actions button
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:30%;
    height:40px;
    color:white;
    background-color:#048b9a;
    text-transform:uppercase;
    border-radius:5px;
    border-color:#048b9a;
    cursor:pointer;
    margin-right:1%;
    margin-left:1%;
}

.actus-second
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    height:auto;
    flex-direction:column;
}

.actus-second-titre
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:100%;
    height:50px;
    color:white;
    background-color: #f1c85f;
}

.actus-second-data
{
    display:flex;
    flex-direction:column;
    width:100%;
    height:auto;
    margin-bottom:5%;
}

.actus-second-ligne
{
    display:grid;
    grid-template-rows:auto;
    grid-template-columns:2fr 1fr 1fr 1fr;
    text-align:center;
    color:black;
}

.actus-second-ligne-image
{
    display:flex;
    height:auto;
    width:100%;
    align-items:center;
    justify-content: center;
}

.actus-second-ligne-actions
{
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:100px;
}

.actus-second-ligne-actions button
{
    display:flex;
    align-items:center;
    justify-content: center;
    width:30%;
    height:40px;
    color:white;
    background-color:#048b9a;
    text-transform:uppercase;
    border-radius:5px;
    border-color:#048b9a;
    cursor:pointer;
    margin-right:1%;
    margin-left:1%;
}


.actus-second-ligne-image img
{
    width:50%;
    height:auto;
}
